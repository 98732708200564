import styled, { keyframes, Keyframes } from "styled-components";


interface IActionsContainerProps {
  disable?: boolean;
}

const piscar = keyframes`
  from{
    opacity: 0
  }to{
    opacity: 1 ;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 9fr 1fr;
  grid-template-areas: 
  'aside main'
  'aside footer';
  margin-top: 60px;
  height: auto;
  width: 100%;
`

export const SideContainer = styled.div`
  grid-area: aside;
  padding: 15px 15px 8px;
  background: #262B34;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin-top: -8px;
  #piscar{
    animation: ${piscar} 1s infinite;
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 1px;
    align-self: center;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 10px;
  height: 30px;
  align-self: flex-start;
  >h2{
    font-size: .875rem;
  }
  >p{
    font-size: .875rem;
  }

 
`

export const ClientVideo = styled.video`
  margin: 50px 0 15px;
  width: 80%;
  height: 60vh;
  border-radius: 4px;
  object-fit: cover;
`


export const CallOptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100px;
  background: rgba(0,0,0,.7);
  align-items: center;
  border-radius: 20px;
`


export const CallDuration = styled.p`
  bottom: 10px;
  right: 10px;
`

export const MainSectionContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  > p {
    font-size: 18px;
    color: rgba(0,0,0, .25);
    margin-top: 15px;
  }
`

export const BottomContainer = styled.div`
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px 10px;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`

export const ActionOptionsContainer = styled.div<IActionsContainerProps>`
  display: flex;
  align-items: center;
  gap: 40px;
  
  ${({ disable }) => {
    if (disable) return ` pointer-events: none;`
  }}

`

interface IButtonProps {
  size: number;
  color?: string;
  notHover?: boolean
}




export const SelfVideo = styled.video`
  border-radius: 4px;
  width: 80px;
  height: 80px;
  object-fit: cover;
`