import {
  ActionOptionsContainer,
  BottomContainer,
  CallDuration,
  CallOptionsContainer,
  ClientVideo,
  Container,
  InfoContainer,
  MainSectionContainer,
  SelfVideo,
  SideContainer
} from "./styles"
import { BsTelephoneFill, BsFillCameraFill } from 'react-icons/bs';
import { FaQrcode } from 'react-icons/fa';
import { VscChecklist } from 'react-icons/vsc';
import ApiUser from "../../services/ApiUser";
import { useContext, useEffect, useRef, useState } from "react";
import Checklists from "./components/checklists";
import OccurenceForm from "./components/occurenceform";
import Options from "../../components/options"
import useStopWatch from "../../hooks/useTopWatch"
import RoundIconButton from "../../components/roundiconbutton"
import CallMethodsContext from "../../contexts/callmethods"
import ReInfo from "./components/reinfo"
import { useTheme } from "styled-components";
import ToastContext from "../../contexts/toast"

interface IOnCallProps {
  finishCall: () => void,
  localStream: any,
  remoteStream: any,
  takePicture: any,
  readQrCode: any,
  connectionState: any
}

const InCallScreen: React.FC<IOnCallProps> = ({ finishCall, localStream, remoteStream, takePicture, readQrCode, connectionState }) => {
  const clientVideo = useRef<any>();

  const selfVideo = useRef<any>();
  const [isCheckListOpen, setIsCheckListOpen] = useState<boolean>(false);
  const [isOccurenceFormOpen, setIsOccurenceFormOpen] = useState<boolean>(false);
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);
  const noAction = !isCheckListOpen && !isOccurenceFormOpen && !isNotesOpen;
  const [showChoiceCheckList, setShowChoiceCheckList] = useState(false);
  const [loadingCheckList, setLoadingChecklist] = useState<boolean>(false)
  const [listCheck, setListCheck] = useState<any[]>([]);
  const [listOptionsCheck, setListOptionsCheck] = useState<any[]>([]);
  const [checkChoiced, setCheckChoiced] = useState<any>({});
  const [_duration, startStopWatch, resetStopWatch] = useStopWatch();
  const { setCurrentPic, setCurrentReadQrCode, currentCalled, userReCalled, tryingReconnect, setTryingReconnect } = useContext(CallMethodsContext);
  const [companies, setCompanies] = useState<any>()
  const theme = useTheme();


  useEffect(() => {
    selfVideo.current.srcObject = localStream;
  }, [localStream]);





  useEffect(() => {
    clientVideo.current.srcObject = remoteStream;
    if (!remoteStream) setTryingReconnect(false);
  }, [remoteStream]);


  useEffect(() => {
    startStopWatch();
    return () => resetStopWatch();
  }, [])





  useEffect(() => {
    if (connectionState === 'disconnected') {
      setTryingReconnect(true);
    }
  }, [connectionState])



  async function takePic() {
    const { videoWidth, videoHeight } = clientVideo.current;
    const pic = await takePicture(videoWidth, videoHeight, clientVideo);
    if (pic) {
      setCurrentPic(pic, isCheckListOpen);
    }
  }





  async function readQR() {
    const { videoWidth, videoHeight } = clientVideo.current;
    const value = await readQrCode(videoWidth, videoHeight, clientVideo);
    if (value) {
      setCurrentReadQrCode(value, isCheckListOpen);
    }
  }




  async function openChecklist() {
    try {
      setLoadingChecklist(true);
      const cliente = 10550;
      const local = 10551;
      let allChecklist: any = [];

      //Locais de servico
      let chLocalTrabalhoGeral = await ApiUser.getChecklists({
        tipo: 1,
        abrangencia: 1,
        geracao: 2,
      });

      if (!chLocalTrabalhoGeral.error && chLocalTrabalhoGeral.checklists) allChecklist = [...allChecklist, ...chLocalTrabalhoGeral.checklists]


      let chLocalTrabalhoCliente: any = await ApiUser.getChecklists({
        tipo: 1,
        abrangencia: 2,
        geracao: 2,
        cliente,
      });
      if (!chLocalTrabalhoCliente.error && chLocalTrabalhoCliente.checklists) allChecklist = [...allChecklist, ...chLocalTrabalhoCliente.checklists]



      let chLocalTrabalhoLocal: any = await ApiUser.getChecklists({
        tipo: 1,
        abrangencia: 3,
        geracao: 2,
        cliente,
        local
      });
      if (!chLocalTrabalhoLocal.error && chLocalTrabalhoLocal.checklists) allChecklist = [...allChecklist, ...chLocalTrabalhoLocal.checklists]
      // Fim Locais de Servico




      //Funcionário
      let chFuncionarioGeral: any = await ApiUser.getChecklists({
        tipo: 2,
        abrangencia: 1,
        geracao: 2,
      });
      if (!chFuncionarioGeral.error && chFuncionarioGeral.checklists) allChecklist = [...allChecklist, ...chFuncionarioGeral.checklists]


      let chFuncionarioCliente: any = await ApiUser.getChecklists({
        tipo: 2,
        abrangencia: 2,
        geracao: 2,
        cliente,
      });
      if (!chFuncionarioCliente.error && chFuncionarioCliente.checklists) allChecklist = [...allChecklist, ...chFuncionarioCliente.checklists]


      let chFuncionarioLocal: any = await ApiUser.getChecklists({
        tipo: 2,
        abrangencia: 3,
        geracao: 2,
        cliente,
        local
      });
      if (!chFuncionarioLocal.error && chFuncionarioLocal.checklists) allChecklist = [...allChecklist, ...chFuncionarioLocal.checklists]
      // Fim Locais de Funcionário



      //Armas
      let chArmasGeral: any = await ApiUser.getChecklists({
        tipo: 5,
        abrangencia: 1,
        geracao: 2,
      });
      if (!chArmasGeral.error && chArmasGeral.checklists) allChecklist = [...allChecklist, ...chArmasGeral.checklists]


      let chArmasCliente: any = await ApiUser.getChecklists({
        tipo: 5,
        abrangencia: 2,
        geracao: 2,
        cliente,
      });
      if (!chArmasCliente.error && chArmasCliente.checklists) allChecklist = [...allChecklist, ...chArmasCliente.checklists]


      let chArmasLocal: any = await ApiUser.getChecklists({
        tipo: 5,
        abrangencia: 3,
        geracao: 2,
        cliente,
        local
      });
      if (!chArmasLocal.error && chArmasLocal.checklists) allChecklist = [...allChecklist, ...chArmasLocal.checklists]
      // Fim Locais de Funcionário



      //Equipamentos
      let chEquipamentosGeral: any = await ApiUser.getChecklists({
        tipo: 6,
        abrangencia: 1,
        geracao: 2,
      });
      if (!chEquipamentosGeral.error && chEquipamentosGeral.checklists) allChecklist = [...allChecklist, ...chEquipamentosGeral.checklists]


      let chEquipamentosCliente: any = await ApiUser.getChecklists({
        tipo: 6,
        abrangencia: 2,
        geracao: 2,
        cliente,
      });
      if (!chEquipamentosCliente.error && chEquipamentosCliente.checklists) allChecklist = [...allChecklist, ...chEquipamentosCliente.checklists]


      let chEquipamentosLocal: any = await ApiUser.getChecklists({
        tipo: 6,
        abrangencia: 3,
        geracao: 2,
        cliente,
        local
      });
      if (!chEquipamentosLocal.error && chEquipamentosLocal.checklists) allChecklist = [...allChecklist, ...chEquipamentosLocal.checklists]
      // Fim Locais de Funcionário



      //Geral
      let chGeralGeral: any = await ApiUser.getChecklists({
        tipo: 8,
        abrangencia: 1,
        geracao: 2,
      });
      if (!chGeralGeral.error && chGeralGeral.checklists) allChecklist = [...allChecklist, ...chGeralGeral.checklists]


      let chGeralCliente: any = await ApiUser.getChecklists({
        tipo: 8,
        abrangencia: 2,
        geracao: 2,
        cliente,
      });
      if (!chGeralCliente.error && chGeralCliente.checklists) allChecklist = [...allChecklist, ...chGeralCliente.checklists]



      let chGeralLocal: any = await ApiUser.getChecklists({
        tipo: 8,
        abrangencia: 3,
        geracao: 2,
        cliente,
        local
      });
      if (!chGeralLocal.error && chGeralLocal.checklists) allChecklist = [...allChecklist, ...chGeralLocal.checklists]

      setLoadingChecklist(false);

      if (allChecklist.length > 0) {
        setListCheck(allChecklist);
        const aux = allChecklist.map((i: any) => {
          return {
            label: i.nomeModelo,
            value: i.modelo
          }
        })
        setListOptionsCheck(aux);
        setShowChoiceCheckList(true)
      }

    } catch (error) {
      setLoadingChecklist(false);
    }

  }





  function closeOccurenceForm() {
    setIsOccurenceFormOpen(false)
  }




  function closeChecklist() {
    setIsCheckListOpen(false)
  }



  function handleCheckListChoiced(modelo: string | number) {
    const local = 10551;
    const _checkChoiced = listCheck.filter(i => i.modelo === modelo);

    _checkChoiced[0].entidade = local;
    setCheckChoiced(_checkChoiced[0]);
    setShowChoiceCheckList(false);
    setIsCheckListOpen(true);
  }




  function finalizaCall() {

  }




  return (
    <>
      {showChoiceCheckList && <Options title={"Selecione o Checklist"} selectOption={handleCheckListChoiced} close={() => setShowChoiceCheckList(false)} itens={listOptionsCheck} />}
      <Container>
        <SideContainer>
          {!tryingReconnect && <InfoContainer>
            <h2>Cliente:</h2>
            <p>{currentCalled?.cliente}</p>
            <h2>Local:</h2>
            <p>{currentCalled?.local}</p>
            <h2>Duração: </h2>
            <p>{_duration}</p>
          </InfoContainer>}

          {
            tryingReconnect && <h2 id={'piscar'}>Reconectando ...</h2>
          }

          <ClientVideo playsInline ref={clientVideo} autoPlay />


          <CallOptionsContainer>

            <RoundIconButton
              disabled={!userReCalled}
              buttonSize={40}
              onClick={takePic}
              icon={<BsFillCameraFill
                color="#fff"
                size={20}
              />}
            />


            <RoundIconButton
              color={theme.colors.red}
              buttonSize={40}
              onClick={finishCall}
              icon={<BsTelephoneFill
                color={theme.colors.white}
                size={18}
                style={{ transform: 'rotate(135deg)' }}
              />}
            />
          </CallOptionsContainer>


        </SideContainer>

        <MainSectionContainer>
          {!userReCalled && <ReInfo />}
          {isCheckListOpen && <Checklists closeModal={closeChecklist} checkList={checkChoiced} />}
          {isOccurenceFormOpen && <OccurenceForm closeModal={closeOccurenceForm} />}
          {noAction &&
            <>
              <img src="assets/undraw_a_day_off_re_hedl.svg" width={400} />
              <p>Nenhuma ação iniciada</p>
            </>
          }
        </MainSectionContainer>

        <BottomContainer>
          <ActionOptionsContainer disable={(isCheckListOpen || isOccurenceFormOpen) || !userReCalled}>
            <RoundIconButton
              buttonSize={40}
              color={isCheckListOpen ? theme.colors.primary : theme.colors.darkSilver}
              onClick={openChecklist}
              isLoading={loadingCheckList}
              icon={<VscChecklist
                color={theme.colors.white}
                size={18}
                style={{ transform: 'translateX(-2px) translateY(-1px)' }}
              />}
              label={'Check-list'}
            />
          </ActionOptionsContainer>

          <SelfVideo playsInline ref={selfVideo} autoPlay muted />
        </BottomContainer>
      </Container>
    </>
  )
}

export default InCallScreen;

