import React, { useContext, useEffect, useRef } from "react";
import { useCallback, useState } from "react";


import { VscRefresh } from "react-icons/vsc"
import ListDevices from "./components/listdevices"
import { ICustumer, ILocalSupervisaoRemota } from "../../@interfaces"
import { MdOutlinePhoneInTalk } from "react-icons/md"
import CallMethodContext from "../../contexts/callmethods";
import { BsTelephoneFill } from 'react-icons/bs';
import RoundIconButton from "../../components/roundiconbutton"
import CardCustumer from "../../components/cardcustumer";
import MySpace from "../../components/myspace"
import TooTip from "../../components/tootip";
import ApiUser from "../../services/ApiUser";
import Raiting from "../../components/finalservice"
import TurnosContext from "./../../contexts/turno"
import ToastContext from "../../contexts/toast";
import NoData from "../../components/nodata";
import {
  Container,
  Content,
  SearchInput,
  SideContainer,
  Ul,
  CallingOverlay,
  OrderButton,
  ContainerOrder,
  GridOrder,
  ContainerRefresh
} from "./styles";
import { cloneDeep, orderBy } from "lodash";
import storage from "../../storage";
import CustumerContext from "../../contexts/custumers";







interface IHomeProps {
  startCall: (id: string) => void;
  pending: boolean;
  finishCall: () => void;
  callbackFinishCall?: () => void;
  socketID?: any;
}




type TOrder = 'nomeCliente' | 'prioridade' | 'percent' | null


const Home: React.FC<IHomeProps> = ({ startCall, pending, finishCall, socketID }) => {
  const [data, setData] = useState<ICustumer[]>([]);
  const [showDevices, setShowDevices] = useState<boolean>(false);
  const [dataDevicesToShow, setDataDevicesToShow] = useState<ILocalSupervisaoRemota | null>(null);
  const { currentCalled, setCurrentCalled, showRaiting, setUserReCalled, setCallingState, callingState, tryingReconnect } = useContext(CallMethodContext);
  const [activeOrder, setActiveOrder] = useState<TOrder>(null);
  const { setAllTurnos, turnoValue } = useContext(TurnosContext);
  const { showErrorToast } = useContext(ToastContext);
  const [loadingDevice, setLoadingDevice] = useState(false);
  const { custumerList, getCustumerListFromApi, isLoading } = useContext(CustumerContext);
  const [calling, setCalling] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const currentTurno = useRef(turnoValue);
  const lastDataPut = useRef<any>([]);
  const timer = useRef<any>(null);

  useEffect(() => {
    setCallingState('none');

    if (!turnoValue) {
      loadTurnos();
    } else {
      if (data.length === 0) {
        getCustumerListFromApi();
      }
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    }

  }, [])





  useEffect(() => {
    setData(custumerList);
  }, [custumerList])



  useEffect(() => {
    if (turnoValue) {
      if (currentTurno.current !== turnoValue) {
        currentTurno.current = turnoValue;
        getCustumerListFromApi();
      }
    }
  }, [turnoValue])





  function refresh() {
    if (turnoValue) {
      getCustumerListFromApi();
    } else {
      loadTurnos();
    }
  }




  async function loadTurnos(): Promise<number> {
    try {
      const turnos = await ApiUser.getTurnos();

      if (!turnos.error) {
        if (turnos.turnosTrabalho) {
          setAllTurnos(turnos.turnosTrabalho);
          return turnos.turnosTrabalho[0].codturno
        }
      }
      return 0;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  }






  async function openDevices(id: number) {
    try {
      setLoadingDevice(true);
      setUserReCalled(null);
      storage.cleanSection();

      const custumer = data.filter((i: any) => i.id === id)[0];
      const { relCliente, relLocal, progSupRemota } = custumer;
      const localSup = await ApiUser.getCustumer(turnoValue, relCliente, relLocal, progSupRemota);

      setCurrentCustumerCall(custumer);
      setDataDevicesToShow(localSup);
      setShowDevices(true);
      setLoadingDevice(false);
    } catch (error) {
      setLoadingDevice(false);
      throw new Error(`openDevices: ` + error);
    }
  }




  function setCurrentCustumerCall(custumer: ICustumer) {
    storage.setSectionCall({
      chaveExec: 0,
      custumer: custumer,
      relFuncionario: 0
    })
  }



  function closeDevices() {
    setShowDevices(false);
  }




  async function handleDeciceChoiced(chave: string) {
    // Aqui emite o push e a guarda retorno
    try {
      setCallingState('Chamando ...');
      setCalling(true);
      // Na realidade, aqui ele vai pegar o hash de push notification
      const pushIdResponse = await ApiUser.getSocketID(chave);

      if (pushIdResponse.error) {
        setCallingState('none');
        setCalling(false);
        showErrorToast('Ligação não completada: ' + pushIdResponse.message);
        return;
      }

      const response = await ApiUser.sendCallSolicitation(socketID, pushIdResponse.socketID!);


      if (response.error) {
        setCallingState('none');
        setCalling(false);
        showErrorToast('Ligação não completada: ' + pushIdResponse.message);
        return;
      }

      const sectionCall = storage.getSectionCall();
      const { nomeCliente, nomeLocal } = sectionCall.custumer;

      setCurrentCalled({
        cliente: nomeCliente,
        local: nomeLocal,
        descricao: '',
        chave: chave
      })

      timer.current = setTimeout(() => {
        setCallingState('none');
        setCalling(false);
        showErrorToast('Ligação não completada. Device chamado não respondeu.')
      }, 60000)

      return;
    } catch (error) {
      setCalling(false);
    }
  }





  function order(orderParam: TOrder) {
    if (activeOrder === orderParam) return;
    setData([]);
    setActiveOrder(orderParam);
    const strOrder = (orderParam === 'prioridade') ? 'desc' : 'asc'
    const _data: any = orderBy(cloneDeep(data), [orderParam], [strOrder]);
    console.log({ semFiltrar: data, filtrando: _data });
    setData(_data);
  }





  function redial() {
    if (currentCalled) {
      handleDeciceChoiced(currentCalled.chave);
    }
  }



  function stopCall() {
    setCallingState('none');
    setCalling(false);
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  }


  function handleSearch(value: string) {
    setSearch(value)
  }


  const filtered = search.length > 0 ? data.filter(i => i.nomeCliente.toUpperCase().includes(search.toUpperCase())) : data;



  return (
    <>
      {showDevices && <ListDevices
        close={closeDevices}
        dataDevices={dataDevicesToShow!}
        handle={handleDeciceChoiced}
      />}
      <Container>
        <SideContainer>
          {(calling || tryingReconnect) && <CallingOverlay>
            {callingState !== 'Estabelecendo conexão ...' && <span className="stateCall animationState">{callingState}</span>}

            {callingState === 'Estabelecendo conexão ...' && <p className={'stateCall animationState'}>{callingState}</p>}

            {callingState !== 'Estabelecendo conexão ...' && <RoundIconButton
              color="#D90429"
              buttonSize={40}
              style={{ marginTop: 30 }}
              onClick={stopCall}
              icon={<BsTelephoneFill
                color="#fff"
                size={18}
                style={{ transform: 'rotate(135deg)' }}
              />}
            />}
          </CallingOverlay>}


          <>
            <SearchInput placeholder="Pesquisar..." onChange={e => handleSearch(e.target.value)} />
            <ContainerOrder>
              <GridOrder>
                <OrderButton active={activeOrder === 'nomeCliente'} onClick={() => order('nomeCliente')}>
                  Cliente
                </OrderButton>

                <OrderButton active={activeOrder === 'prioridade'} onClick={() => order('prioridade')}>
                  Prioridade
                </OrderButton>

                <OrderButton active={activeOrder === 'percent'} onClick={() => order('percent')}>
                  Meta
                </OrderButton>
              </GridOrder>
            </ContainerOrder>
            <ContainerRefresh>

              {currentCalled?.cliente && <TooTip text={`Rediscar para: ${currentCalled.cliente}`}>
                <button className="buttonOptions" onClick={redial}>
                  Rediscar
                  <MdOutlinePhoneInTalk color={'#2991D6'} />
                </button>
              </TooTip>}

              <button className="buttonOptions" onClick={refresh} disabled={isLoading}>
                {isLoading ? 'Carregando Lista ...' : 'Atualizar Lista'}
                {!isLoading ? <VscRefresh color={'#2991D6'} /> : null}
              </button>

            </ContainerRefresh>
          </>



          {filtered.length === 0 && <NoData />}
          <Ul>{filtered?.map((i: ICustumer, index: number) => {
            return (
              <CardCustumer
                color="blue"
                onClick={() => openDevices(i.id)}
                data={i}
                delay={index * .200}
                key={index}
              />
            )
          })}</Ul>
        </SideContainer>

        <Content>
          {showRaiting && <Raiting />}
          <MySpace />
        </Content>
      </Container>
    </>
  )
}


export default Home;




